.root {
  @apply block;
}

.ti-input {
  @apply w-full rounded border border-gray-300 bg-white;
  line-height: 1.5rem;
}

.ti-icon-left {
  @apply absolute inset-y-0 left-0 flex items-center justify-center p-3;
}

.ti-icon-right {
  @apply absolute inset-y-0 right-0 flex items-center justify-center p-3;
}

.ti-error {
  @apply border-red-500;
}

.ti-disabled {
  @apply cursor-not-allowed bg-gray-200 text-gray-600;
}
