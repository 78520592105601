.btn-container {
  @apply inline-block font-sans-serif text-base font-medium;
}

.btn {
  @apply relative inline-flex cursor-pointer items-center whitespace-nowrap rounded border border-solid border-transparent text-center transition;
  font-family: inherit;
  font-weight: inherit;
}

.btn--w-full {
  @apply flex w-full items-center justify-center;
}

.btn--w-full-icon-right {
  @apply justify-between;
}

.btn:disabled {
  @apply cursor-not-allowed opacity-75;
  pointer-events: none;
}

.btn-icon {
  @apply mr-2;
}

.btn-icon-right {
  @apply pl-2;
}

.btn--square {
  @apply flex items-center justify-center;
}

.btn--square > .btn-icon {
  @apply m-0 inline-block;
}

/*
  Button sizes
*/

.btn--tiny {
  @apply px-2 py-1 text-sm font-medium leading-4;
}

.btn--tiny.btn--square {
  @apply p-1;
}

.btn--small {
  @apply px-3 py-2 text-sm font-medium leading-4;
}

.btn--base {
  @apply px-3 py-1.5 text-sm font-medium leading-4;
}

.btn--medium {
  @apply rounded-md px-4 py-2 text-sm font-medium;
}

.btn--large {
  @apply rounded-md px-4 py-2 text-base font-medium;
}

.btn--small.btn--square,
.btn--medium.btn--square,
.btn--large.btn--square {
  @apply p-2;
}

.btn--xlarge {
  @apply rounded-md px-6 py-3 text-base font-medium;
}

.btn--xlarge.btn--square {
  @apply rounded-md p-3;
}

/*
  Button variants
*/
.btn-primary {
  @apply bg-indigo-600 text-white hover:bg-indigo-700 hover:shadow-indigo focus:bg-indigo-700;
}

.btn-success {
  @apply bg-lime-500 text-white hover:bg-lime-700 hover:shadow;
}

.btn-dark {
  @apply bg-gray-900 text-white hover:bg-gray-800 hover:shadow focus:bg-gray-700;
}

.btn-light {
  @apply bg-gray-100 text-gray-700 hover:bg-gray-200 focus:bg-gray-300;
}

.btn-white {
  @apply border border-white border-opacity-20 bg-white bg-opacity-5 text-white backdrop-blur-md hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-30;
}

.btn-outline {
  @apply border border-gray-300 bg-white text-gray-700 shadow-xs hover:bg-gray-100 focus:bg-gray-200;
}

.btn-text {
  @apply border-none bg-transparent text-gray-700 hover:bg-gray-100 focus:bg-gray-200;
}

.btn-primary.btn--disabled,
.btn-dark.btn--disabled {
  @apply bg-gray-500 text-white;
}

.btn-light.btn--disabled {
  @apply bg-gray-100 text-gray-500;
}

.btn-link.btn--disabled {
  @apply text-gray-500;
}

.btn-outline.btn--disabled {
  @apply border-gray-500 text-gray-500 shadow-none;
}

.btn--danger {
  @apply border-red-500 text-red-500;
  text-shadow: 0 0 4px rgba(229,  62,  62, .5);
}

.btn-primary.btn--danger {
  @apply bg-red-500 text-white;
  @apply hover:border-red-600 hover:bg-red-500 hover:shadow-red;
}
.btn-default.btn--danger,
.btn-secondary.btn--danger,
.btn-outline.btn--danger,
.btn-dashed.btn--danger,
.btn-link.btn--danger,
.btn-text.btn--danger {
  @apply hover:border-red-600 hover:bg-red-500 hover:text-white hover:shadow-red;
}

.btn-loader--center {
  position: absolute;

  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  margin: auto 0;

  width: inherit;
  height: inherit;
}
