@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./misc.css";

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings:
    "case" 1,
    "rlig" 1,
    "calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply relative m-0 min-h-full bg-gray-50 p-0 font-sans-serif text-gray-900;
}

@layer utilities {
  .fit {
    /* content without the header and its border */
    min-height: calc(100vh - 3rem - 1px);
  }

  .margin-header {
    margin-top: calc(3rem);
  }

  .fit-full {
    /* content without the header and its border */
    height: calc(100vh - 3rem - 1px);
    min-height: calc(100vh - 3rem - 1px);
    max-height: calc(100vh - 3rem - 1px);
  }

  .fit-full-farm {
    /* content without the header and its border */
    height: calc(100vh - 7.5rem - 1px);
    min-height: calc(100vh - 7.5rem - 1px);
    max-height: calc(100vh - 7.5rem - 1px);
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .text-overline {
    @apply text-xs uppercase tracking-widest;
  }

  .bg-blur {
    backdrop-filter: blur(8px);
  }

  .focus-ring {
    @apply focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-white;
  }
  input.focus-ring,
  textarea.focus-ring {
    @apply focus:ring-offset-0;
  }

  .text-shadow {
    text-shadow:
      1px 1px 2px rgba(0, 0, 0, 0.24),
      1px 1px 0px rgba(0, 0, 0, 0.24);
  }

  .text-shadow-none {
    text-shadow: unset;
  }

  .link {
    @apply text-indigo-600 underline hover:cursor-pointer hover:text-indigo-800;
  }
}

.logo-gradient {
  background: linear-gradient(
    87.27deg,
    #f68c2b 3.45%,
    #fc2d30 19.07%,
    #eb177f 34.8%,
    #b728c5 49.69%,
    #5750f1 63.67%,
    #2095d6 76.26%,
    #1bc29d 87.59%,
    #20cc4a 99.64%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media print {
  .no-print,
  .no-print * {
    display: none;
  }
}

@media print {
  .print {
    display: block !important;
  }
}

@media print {
  .print-content {
    margin: 0 !important;
  }
}

@import "./nprogress.css";
@import "./mapbox-geocoder.css";
@import "./mapbox-popup.css";
