.base {
  @apply flex items-center rounded border p-3 text-sm font-medium;
}

.info {
  @apply border-indigo-400 bg-indigo-100 text-indigo-800;
}

.success {
  @apply border-lime-400 bg-lime-100 text-lime-900;
}

.warning {
  @apply border-yellow-400 bg-yellow-100 text-yellow-900;
}

.error {
  @apply border-red-400 bg-red-100 text-red-900;
}

.icon {
  @apply mr-3;
}

.icon.success {
  @apply text-lime-500;
}
.icon.warning {
  @apply text-yellow-500;
}
.icon.error {
  @apply text-red-500;
}
.icon.info {
  @apply text-indigo-500;
}

.closeButton {
  @apply ml-auto;
}

.action {
  @apply ml-auto mr-4 text-indigo-600;
}

.action + .closeButton {
  @apply ml-0;
}
