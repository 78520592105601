.title {
  @apply font-serif font-medium;
}

/* allows placing an anchor on the title and give it some room on top */
.title[id]::before {
  display: block;
  content: " ";
  margin-top: -4rem;
  height: 4rem;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
}

h1.title {
  @apply my-2 text-2xl leading-9 tracking-tight lg:text-3xl;
}

h2.title {
  @apply my-1.5 text-xl leading-7 tracking-tight lg:text-2xl;
}

h3.title {
  @apply my-1 text-lg leading-6 tracking-tight lg:text-xl;
}

h4.title {
  @apply font-sans-serif text-base leading-5 lg:text-lg;
}

h5.title {
  @apply font-sans-serif text-base;
}

h6.title {
  @apply font-sans-serif text-sm;
}
