.mapboxgl-popup-content {
  @apply !bg-transparent !p-0;
}

.parcel-tooltip-content .mapboxgl-popup-content {
  @apply w-64 overflow-hidden !rounded-md !bg-white !p-0 !shadow-md !ring-1 !ring-black !ring-opacity-5;
}

.mapboxgl-popup-tip {
  @apply z-10 h-0 w-0 border-8 border-transparent;
}
